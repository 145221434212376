import myCustomAxios from '../services/myAxios';
import config from '../config.json';


    async function Catalogo (accessToken, refreshToken, catalogType, id)
    {
        let myCatalog=[];
        
        try {
            const bod = {Id:id, CatalogueType: catalogType };
            const values = JSON.stringify(bod);
      
            const headers= {
                "Access-Control-Allow-Origin": true,
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            }
    
            const customAxios = myCustomAxios(accessToken, refreshToken);
            let url = process.env.REACT_APP_API + config.API_ESOURCING_CATALOGO;
            await customAxios.patch(
                                    url,
                                    values,
                                    { headers }).then((res) => {
                                        //console.log(res.data)
                                        let valores = [];
                                        let item = {
                                          value: 0,
                                          label: "",
                                        };
                                        res.data.forEach(element => {
                                            item = {value: element.id, label: element.name}
                                            valores.push(item);
                                        });
                                        myCatalog = valores;
            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            })

            return myCatalog;

        } catch (error) {
            console.log(error);
            return [];
          }
    }

    export default Catalogo;