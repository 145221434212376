const authReducer = (state = {
    user: '',
    isAuthenticated: false,
    selectOficina:0,
    accessToken:'',
    refreshToken:''
}, action) => {
    switch (action.type) {
        case "LOGIN":
            state = { ...state, user: action.payload, isAuthenticated: true, selectOficina:action.payload.selectOficina, accessToken: action.payload.token, refreshToken: action.payload.refreshToken};
            break;
            case "LOGOUT":
                state = { ...state, user: '', isAuthenticated: false, selectOficina:0 };
                break;
            case "OFICE":
                state = { ...state, selectOficina:action.payload };
                break;
                case "SETTOKEN":
                    state = { ...state, accessToken:action.payload.accessToken, refreshToken: action.payload.refreshToken };
                    break;
                default:
            break;
    };
    return state;
};

export default authReducer;